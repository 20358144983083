/**
 * Obsidian style
 * ported by Alexander Marenin (http://github.com/ioncreature)
 */

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #282b2e;
  -webkit-text-size-adjust: none;
}

.hljs-keyword,
.hljs-literal,
.hljs-change,
.hljs-winutils,
.hljs-flow,
.nginx .hljs-title,
.css .hljs-id,
.tex .hljs-special {
  color: #93c763;
}

.hljs-number {
  color: #ffcd22;
}

.hljs {
  color: #e0e2e4;
}

.css .hljs-tag,
.css .hljs-pseudo {
  color: #d0d2b5;
}

.hljs-attribute,
.hljs .hljs-constant {
  color: #668bb0;
}

.xml .hljs-attribute {
  color: #b3b689;
}

.xml .hljs-tag .hljs-value {
  color: #e8e2b7;
}

.hljs-code,
.hljs-class .hljs-title,
.hljs-header {
  color: white;
}

.hljs-class,
.hljs-hexcolor {
  color: #93c763;
}

.hljs-regexp {
  color: #d39745;
}

.hljs-at_rule,
.hljs-at_rule .hljs-keyword {
  color: #a082bd;
}

.hljs-doctype {
  color: #557182;
}

.hljs-link_url,
.hljs-tag,
.hljs-tag .hljs-title,
.hljs-bullet,
.hljs-subst,
.hljs-emphasis,
.hljs-type,
.hljs-preprocessor,
.hljs-pragma,
.ruby .hljs-class .hljs-parent,
.hljs-built_in,
.django .hljs-template_tag,
.django .hljs-variable,
.smalltalk .hljs-class,
.hljs-javadoc,
.django .hljs-filter .hljs-argument,
.smalltalk .hljs-localvars,
.smalltalk .hljs-array,
.hljs-attr_selector,
.hljs-pseudo,
.hljs-addition,
.hljs-stream,
.hljs-envvar,
.apache .hljs-tag,
.apache .hljs-cbracket,
.tex .hljs-command,
.hljs-prompt,
.hljs-name {
  color: #8cbbad;
}

.hljs-string {
  color: #ec7600;
}

.hljs-comment,
.hljs-annotation,
.hljs-blockquote,
.hljs-horizontal_rule,
.hljs-decorator,
.hljs-pi,
.hljs-deletion,
.hljs-shebang,
.apache .hljs-sqbracket,
.tex .hljs-formula {
  color: #818e96;
}

.hljs-keyword,
.hljs-literal,
.css .hljs-id,
.hljs-phpdoc,
.hljs-dartdoc,
.hljs-title,
.hljs-header,
.hljs-type,
.vbscript .hljs-built_in,
.rsl .hljs-built_in,
.smalltalk .hljs-class,
.diff .hljs-header,
.hljs-chunk,
.hljs-winutils,
.bash .hljs-variable,
.apache .hljs-tag,
.tex .hljs-special,
.hljs-request,
.hljs-at_rule .hljs-keyword,
.hljs-status {
  font-weight: bold;
}

.coffeescript .javascript,
.javascript .xml,
.tex .hljs-formula,
.xml .javascript,
.xml .vbscript,
.xml .css,
.xml .hljs-cdata {
  opacity: 0.5;
}
